import IExtension from "./IExtension";
import {Naja} from "naja";
import showLoader from "../helpers/showLoader";

export default class ConfirmExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.uiHandler.addEventListener('interaction', e => {
            const $el = $(e.detail.element);
            if ($el.data('confirm')) {
                this.processConfirm(e, $el);
            }
        });

    }

    private processConfirm(e, $target): void {
        const question = $target.data('confirm');
        const yes = $target.data('confirm-yes');
        const no = $target.data('confirm-no');
        const confirmed = $target.data('confirmed');

        const loaderTargetClosest = $target.data('loader-target-closest');

        if (question && !confirmed) {
            e.preventDefault();
            const $modal = $('[data-confirm-modal-template]');
            const $confirmText = $('[data-confirm-text]', $modal);
            const $confirmYes = $('[data-confirm-yes]', $modal);
            const $confirmNo = $('[data-confirm-no]', $modal);

            $confirmYes.text($confirmYes.data('confirm-default-yes'));
            if (yes) {
                $confirmYes.text(yes);
            }

            $confirmNo.text($confirmNo.data('confirm-default-no'));
            if (no) {
                $confirmNo.text(no);
            }

            $confirmText.text(question);
            $modal.modal('show');

            $('[data-confirm-action]', $modal).off('click.confirm').on('click.confirm', () => {
                $target.data('confirmed', true);

                if(loaderTargetClosest) {
                    showLoader($target.closest(loaderTargetClosest));
                }

                $modal.modal('hide');
                $target[0].click();
            });
        }
    }



}
